<template>
  <div class="page-home">
    <!-- <HomeNav /> -->
    <div class="home-entry">
      <div class="flex">
        <!-- 左侧分类 -->
        <HomeCategory />
        <!-- banner轮播图 -->
        <HomeBanner @bannerList="bannerList" />
        <!-- 右侧 -->
        <qukArea :list="list" v-if="list.length > 0" />
      </div>
      <HomeNew />
      <!-- 成交记录 -->
      <HomeNotice />
      <!-- 热门游戏 -->
      <HomeHot />
      <!-- 顶级账号 -->
      <homeBest />
      <!-- 右侧悬浮 -->
      <rightTool @backTop="backTop" />
      <!-- 登录弹框 -->
      <login></login>
    </div>
  </div>
</template>
<script setup>
import HomeCategory from './components/home-categroy.vue'
import HomeBanner from './components/home-banner.vue'
import HomeNew from './components/home-new.vue'
import HomeNotice from './components/home-notice.vue'
import qukArea from './components/quk-area.vue'
import HomeHot from './components/home-hot.vue'
import homeBest from './components/home-best.vue'
import rightTool from './components/right-tool.vue'
import login from '@/components/Login/index.vue'
import { ref, onMounted, onUnmounted } from 'vue'
const backTopFlag = ref(false) //用来判断样式
const backTop = () => {
  let top = document.documentElement.scrollTop //获取点击时页面的滚动条纵坐标位置
  const timeTop = setInterval(() => {
    document.documentElement.scrollTop = top -= 50 //一次减50往上滑动
    if (top <= 0) {
      clearInterval(timeTop)
    }
  }, 5) //定时调用函数使其更顺滑
}
const handleScroll = () => {
  if (document.documentElement.scrollTop > 20) {
    backTopFlag.value = true
  } else {
    backTopFlag.value = false
  }
  //往下滑超过20则显示 否则则不显示按钮
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
}) //监听滚动事件
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
const list = ref([])
const bannerList = (val) => {
  list.value = val
}
</script>
<style scoped lang="less">
.page-home {
  background: url('~@/assets/images/home-bg.png') no-repeat;
  background-size: cover;
  .home-entry {
    width: 1200px;
    margin: 0 auto;
    .flex {
      display: flex;
    }
  }
}
</style>
