<template>
  <div class="home-category">
    <!-- 正式数据渲染 -->
    <template v-if="categoryList.length > 0">
      <ul class="menu">
        <li v-for="item in categoryList" :key="item.id" @click="gopage(item)">
          <img :src="item.image" alt="" />
          <div class="right">
            <p>{{ item.name }}</p>
            <p>{{ item.description }}</p>
          </div>
        </li>
      </ul>
    </template>
    <!-- 骨架屏占位 -->
    <!-- <template v-else>
      <ul class="menu">
        <li v-for="i in 9" :key="i">
          <XtxSkeleton
            :width="40"
            :height="20"
            style="margin-right: 5px"
            bg="rgba(255,255,255,0.2)"
          />
          <XtxSkeleton
            :width="50"
            :height="20"
            bg="rgba(255,255,255,0.2)"
            style="margin-right: 5px"
          />
          <XtxSkeleton :width="50" :height="20" bg="rgba(255,255,255,0.2)" />
        </li>
      </ul>
    </template> -->
  </div>
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue'
import { game } from '@/api/home'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const categoryList = ref([])
const loadCategory = () => {
  game().then((res) => {
    if (res.code == 200) {
      categoryList.value.push(res.data[0], res.data[1], res.data[2], res.data[3], res.data[4])
    }
  })
}
loadCategory()

const gopage = (i) => {
  router.push({ path: '/wares', query: { id: i.id, gameName: i.name, type: 'buy' } })
}
onMounted(() => {})
</script>
<style scoped lang="less">
.home-category {
  width: 230px;
  height: 447px;
  background: rgba(0, 0, 0, 0.8);
  position: relative;
  z-index: 99;
  overflow: hidden;
  border-radius: 0 0 12px 12px;
  .menu {
    height: 447px;
    overflow: hidden;
    //padding-top: 24px;
    padding: 25px 10px 25px 23px;
    li {
      display: flex;
      cursor: pointer;
      img {
        width: 56px;
        height: 56px;
      }
      .right {
        margin-left: 20px;
        padding-top: 6px;
        p:nth-child(1) {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
        }
        p:nth-child(2) {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-top: 2px;
        }
      }
      &:hover {
        //background: @xtxColor;
      }
    }
    li:not(:last-child) {
      margin-bottom: 28px;
    }
  }
}
</style>
