<template>
  <div class="order-record-box">
    <div class="tit">
      <img src="@/assets/images/notice.png" alt="" />
      <div class="tit-text">
        <p>成交</p>
        <p>速报</p>
      </div>
    </div>
    <div class="list">
      <p
        v-for="(item, index) in list"
        :key="index"
        :style="`will-change: transform; transform: translateX(${-left}px);`"
      >
        {{ item.title }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { notice } from '@/api/home'
const list = ref([])
const left = ref(null)
const moveTimer = ref(null)
const getNotice = () => {
  notice().then((res) => {
    if (res.code == 200) {
      list.value = res.data.list
    }
  })
}
getNotice()
const getSlide = () => {
  moveTimer.value = setInterval(() => {
    moveLeft()
  }, 5)
}
getSlide()
const moveLeft = () => {
  if (left.value >= 320) {
    left.value = 0
  } else {
    left.value += 0.2
  }
}
</script>

<style scoped lang="less">
.order-record-box {
  // margin: 0 360px;
  height: 39px;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  margin-top: 24px;
  display: flex;
  .tit {
    display: flex;
    .tit-text {
      font-size: 14px;
      font-family: YouSheBiaoTiHei;
      color: #00b6fa;
      margin-left: 8px;
      font-weight: 600;
    }
  }
  .list {
    width: 1050px;
    white-space: nowrap;
    overflow: hidden;
    margin: 0 auto;
    p {
      display: inline-block;
      margin-left: 20px;
      line-height: 39px;
    }
  }
}
</style>
