<template>
  <div class="main-area">
    <div class="activity-item a" @click="goPage('appraisal')">
      <div class="activity-item-text">
        <p>定价中心</p>
        <p>专业定价，更快出售</p>
        <p>查看详情</p>
      </div>
    </div>
    <div class="activity-item b" style="margin: 0 6px" @click="bidding">
      <div class="activity-item-text">
        <p>竞价专区</p>
        <p>极速竞拍，高价出售</p>
        <p>查看详情</p>
      </div>
    </div>
    <div class="activity-item c" @click="goPage('retrieve')">
      <div class="activity-item-text">
        <p>官方回收</p>
        <p>极速交易，当天结算</p>
        <p>查看详情</p>
      </div>
    </div>
    <biddingModal ref="biddingVisible"></biddingModal>
  </div>
</template>

<script setup>
import biddingModal from '@/components/BiddingModal/index.vue'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()
const biddingVisible = ref(null)
function goPage(e) {
  if (e === 'retrieve') {
    router.push({ path: '/allGame', query: { type: 'recovery' } })
  } else {
    router.push('/appraisal')
  }
}
function bidding() {
  biddingVisible.value.visible = true
}
onMounted(() => {})
</script>

<style scoped lang="less">
.main-area {
  // margin: 0 360px;
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  .activity-item {
    width: 391px;
    height: 140px;

    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    .activity-item-text {
      margin-right: 30px;
      margin-top: 20px;
      p:nth-child(1) {
        font-size: 28px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
      }
      p:nth-child(2) {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 4px;
      }
      p:nth-child(3) {
        width: 68px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        border-radius: 11px;
        border: 1px solid #ffffff;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 12px;
      }
    }
  }
  .a {
    background: url(../../../assets/images/pricing-bg.png) no-repeat;
  }
  .b {
    background: url(../../../assets/images/bidding-bg.png) no-repeat;
  }
  .c {
    background: url(../../../assets/images/retrieve-bg.png) no-repeat;
  }
  .activity-item:hover {
    -webkit-transform: scale(1.05);
  }
}
</style>
