<template>
  <div class="home-banner">
    <a-carousel :after-change="onChange" :autoplay="true" :dots="false">
      <div v-for="(item, index) in bannerList" :key="index" class="item-box">
        <img :src="item.image" alt="" />
      </div>
    </a-carousel>
    <div class="title" v-if="bannerList.length == 1">
      <p
        v-for="(item, index) in bannerList"
        :key="index"
        :class="item.title === bannerList[current].title ? 'active' : ''"
        style="border-radius: 0 0 12px 12px"
      >
        {{ item.title }}
      </p>
    </div>
    <div class="title" v-else>
      <p
        v-for="(item, index) in bannerList"
        :key="index"
        :class="item.title === bannerList[current].title ? 'active' : ''"
      >
        {{ item.title }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { findBanner } from '@/api/home'
import { onMounted, ref } from 'vue'
const emit = defineEmits(['bannerList'])
const bannerList = ref([])
const current = ref(0)
function loadBannerList() {
  findBanner(2).then((res) => {
    if (res.code == 200) {
      bannerList.value = res.data
      emit('bannerList', res.data)
    }
  })
}
function onChange(e) {
  current.value = e
}
// const gopage = (url) => {
//   window.location.href = url
// }
onMounted(() => {
  // 调用函数
  loadBannerList()
})
</script>

<style scoped lang="less">
.home-banner {
  width: 684px;
  height: 434px;
  margin: 0 12px;
  margin-top: 12px;
  position: relative;
}
.ant-carousel :deep(.slick-slide) {
  text-align: center;
  height: 434px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
  border-radius: 12px;
}

.ant-carousel :deep(.slick-slide h3) {
  color: #fff;
}
.item-box {
  border-radius: 12px;
  img {
    height: 434px;
    border-radius: 12px;
  }
}
.title {
  width: 684px;
  height: 40px;
  position: absolute;
  display: flex;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  // background: #000000;
  // opacity: 0.51;
  border-radius: 0 0 12px 12px;
  p {
    flex: 1;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
  }
  p:first-child {
    border-radius: 0 0 0 12px;
  }
  p:last-child {
    border-radius: 0 0 12px 0;
  }
  .active {
    background: linear-gradient(135deg, #058aff 0%, #0dbaff 100%);
  }
}
:deep(.slick-list) {
  border-radius: 12px !important;
}
</style>
