<template>
  <div class="hot-box">
    <div class="hot-box-title">
      <div class="left">
        <img src="@/assets/images/hot-icon.png" alt="" />
        <p>热门游戏</p>
      </div>
      <div class="right" @click="pagesClick">
        <span>全部游戏</span>
        <img src="@/assets/images/icon-right-blue.png" alt="" />
      </div>
    </div>
    <div class="hot-slide">
      <div class="hot-slide-box">
        <div class="msg">
          <p>
            POPULAR
            <br />
            GAME
          </p>
          <span>海量游戏 应有尽有</span>
        </div>
        <ul class="hot-slide-menu">
          <li
            :class="current == index ? 'tabs-bg' : ''"
            v-for="(item, index) in menu"
            :key="index"
            @click="changeIndex(index)"
          >
            <img src="@/assets/images/ornament-icon.png" alt="" v-if="current == index" />
            <span>{{ item }}</span>
          </li>
        </ul>
      </div>
      <div class="list">
        <div class="item" v-for="item in list" :key="item.id" @click="gopage(item)">
          <img :src="item.image" alt="" />
          <p class="name">{{ item.name }}</p>
          <div class="item-title">
            <text>-</text>
            <text style="margin: 0 5px">游戏账号</text>
            <text>-</text>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { gameList } from '@/api/home'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const menu = ref(['热门游戏', '热门端戏', '热门手戏'])
const current = ref(0)
const page = reactive({
  pageNum: 10,
  pageSize: 1
})
const list = ref([])
const tepy = ref('-1')
function changeIndex(index) {
  current.value = index
  tepy.value = index - 1
  getList()
}
function getList() {
  const params = {
    type: tepy.value,
    page: page.pageSize,
    page_size: page.pageNum
  }
  gameList(params).then((res) => {
    if (res.code == 200) {
      list.value = res.data.list
    }
  })
}
const pagesClick = () => {
  router.push({ path: '/allGame', query: { type: 'buy' } })
}
const gopage = (i) => {
  router.push({ path: '/wares', query: { id: i.id, type: 'buy' } })
}
onMounted(() => {
  getList()
})
</script>

<style scoped lang="less">
.hot-box {
  // margin: 0 360px;
  margin-top: 35px;
  .hot-box-title {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      p {
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 8px;
        margin-top: 5px;
      }
    }
    .right {
      line-height: 42px;
      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #00b6fa;
        cursor: pointer;
      }
    }
  }
  .hot-slide {
    margin-top: 20px;
    display: flex;
    .hot-slide-box {
      width: 244px;
      height: 401px;
      background: url(../../../assets/images/hot-menu-bg.png) no-repeat;
      padding-top: 46px;
      .msg {
        margin-left: 46px;
        p {
          font-size: 26px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
        }
        span {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          margin-top: 17px;
        }
      }
      .hot-slide-menu {
        margin-top: 32px;
        li {
          width: 173px;
          height: 42px;
          line-height: 42px;
          cursor: pointer;
          text-align: center;
          img {
            vertical-align: 0;
            position: relative;
            top: 3px;
          }
          span {
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
          }
        }
        li:not(:first-child) {
          margin-top: 19px;
        }
        .tabs-bg {
          background: url(../../../assets/images/rectangle-bg.png) no-repeat;
        }
      }
    }
    .list {
      width: 938px;
      height: 400px;
      background: #ffffff;
      border-radius: 12px;
      margin-left: 16px;
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      .item {
        width: 170px;
        height: 170px;
        text-align: center;
        cursor: pointer;
        -webkit-transition: all 0.3s ease;
        img {
          width: 68px;
          height: 68px;
          margin-top: 20px;
        }
        .name {
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          margin-top: 18px;
        }
        .item-title {
          width: 100%;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          margin-top: 5px;
        }
      }
      .item:not(:first-child) {
        margin-left: 7px;
      }
      .item:hover {
        background: url(../../../assets/images/list-hover-bg.png) no-repeat;
        -webkit-transform: scale(1.05);
        p {
          color: #ffffff;
        }
      }
    }
  }
}
</style>
