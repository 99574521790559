<template>
  <div class="quk-area">
    <div class="index-login">
      <div class="index-nologin-area">
        <img src="@/assets/images/avatar.png" alt="" />
        <p>你好，欢迎来到收游帮！</p>
      </div>
      <div class="login-btn">
        <p @click="gopage('allGame')">我要买</p>
        <p @click="visibleClick">我要卖</p>
      </div>
    </div>
    <div class="index-tab-box">
      <div class="slider-line"></div>
      <ul class="index-tab-top">
        <li v-for="(item, index) in tabs" :key="index" @click="gopage('article', item)">
          {{ item }}
        </li>
      </ul>
      <div class="index-tab-bot">
        <div class="index-tab-item" v-for="i in props.list" :key="i" @click="noticeClick(i)">
          {{ i.title }}
        </div>
      </div>
    </div>
    <div class="icon-nav-box">
      <div class="icon-nav-box-item" @click="gopage('service')">
        <img src="@/assets/images/service-icon.png" alt="" />
        <p>联系客服</p>
      </div>
      <div class="icon-nav-box-item" @click="gopage(9)">
        <img src="@/assets/images/purchase-icon.png" alt="" />
        <p>购买指南</p>
      </div>
      <div class="icon-nav-box-item" @click="gopage(10)">
        <img src="@/assets/images/sell-icon.png" alt="" />
        <p>出售指南</p>
      </div>
    </div>
    <sellModal ref="sellVisible"></sellModal>
    <Login ref="loginModal" />
  </div>
</template>
<script setup>
import sellModal from '@/components/SellModal/index.vue'
import Login from '@/components/Login/index.vue'
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { getChatService } from '@/api/service'
const props = defineProps({
  list: Array
})
const router = useRouter()
const tabs = ref(['公告', '帮助中心', '关于我们'])
const sellVisible = ref(null)
const loginModal = ref(null)
function gopage(val, i) {
  if (val === 'allGame') {
    router.push({ path: '/allGame', query: { type: 'buy' } })
  } else if (val === 'article') {
    if (i === '帮助中心') {
      router.push({ path: '/article', query: { id: 5 } })
    } else if (i === '关于我们') {
      router.push({ path: '/article', query: { id: 8 } })
    }
  } else if (val === 9) {
    router.push({ path: '/article', query: { id: 9 } })
  } else if (val === 10) {
    router.push({ path: '/article', query: { id: 10 } })
  } else if (val === 'service') {
    if (localStorage.getItem('token')) {
      getChatService({ type: 1 }).then((res) => {
        if (res.code == 0) {
          let to = router.resolve({ path: '/service', query: { account: res.data.id } })
          window.open(to.href, '_blank')
        }
      })
    } else {
      loginModal.value.visible = true
      loginModal.value.loginVisible = true
    }
  }
}
function visibleClick() {
  sellVisible.value.visible = true
}
const noticeClick = (i) => {
  const reg =
    /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/
  if (reg.test(i.url)) {
    window.location.href = i.url
  }
}
onMounted(() => {})
</script>
<style lang="less">
.quk-area {
  width: 262px;
  height: 434px;
  background: #ffffff;
  border-radius: 12px;
  margin-top: 12px;
  .index-login {
    text-align: center;
    padding-top: 18px;
    .index-nologin-area {
      p {
        margin-top: 10px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
    .login-btn {
      display: flex;
      justify-content: space-between;
      margin: 12px 29px;
      p {
        width: 92px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 15px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff6700;
        border: 1px solid #ff6700;
        cursor: pointer;
      }
      p:nth-child(1) {
        color: #ffffff;
        background: linear-gradient(90deg, #ff9600 0%, #ff7d00 100%);
      }
      p:nth-child(2) {
      }
    }
  }
  .index-tab-box {
    width: 232px;
    margin: 0 auto;
    margin-bottom: 19px;
    position: relative;
    // padding-bottom: 12px;
    border-bottom: 1px solid #f2f2f2;
    .slider-line {
      position: absolute;
      left: 20px;
      top: 35px;
      width: 47px;
      height: 7px;
      background: url(../../../assets/images/slider-line.png) no-repeat;
    }
    .index-tab-top {
      display: flex;
      border-bottom: #eee 1px solid;
      li {
        cursor: pointer;
        flex: 1;
        text-align: center;
        padding: 10px;
      }
    }
    .index-tab-bot {
      padding: 14px 0;
      .index-tab-item {
        cursor: pointer;
        font-size: 13px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
      }
      .index-tab-item:not(:first-child) {
        margin-top: 14px;
      }
      .index-tab-item:hover {
        color: #ff6700;
      }
    }
  }
  .icon-nav-box {
    display: flex;
    .icon-nav-box-item {
      text-align: center;
      flex: 1;
      cursor: pointer;
      p {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-top: 4px;
      }
    }
  }
}
.ant-modal-content {
  border-radius: 20px;
  .ant-modal-body {
    padding: 0;
  }
}
</style>
