<template>
  <div class="best-box">
    <div class="best-box-title">
      <div class="left">
        <img src="@/assets/images/best-icon.png" alt="" />
        <p>顶级账号</p>
      </div>
      <div class="right" @click="gopage('allGame')">
        <span>更多账号</span>
        <img src="@/assets/images/icon-right-blue.png" alt="" />
      </div>
    </div>
    <div class="list">
      <div class="item" v-for="item in list" :key="item.id" @click="gopage('wares', item)">
        <div class="img">
          <img :src="item.image[0]" alt="" />
          <p class="official" v-if="item.admin_id > 0">官方发布</p>
          <p class="user" v-else>用户发布</p>
        </div>
        <div class="introduce">{{ item.title }}</div>
        <div class="price">
          <p>售价</p>
          <p>￥{{ item.price / 100 }}</p>
        </div>
        <div class="district">
          游戏区服：{{ item.params[0].name }}-{{ item.params[0].param_value }}
        </div>
      </div>
    </div>
    <div style="margin-top: 20px; text-align: center">
      <a-pagination
        v-model:current="page.pageSize"
        :total="total"
        @change="onChange"
        :defaultPageSize="8"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, reactive } from 'vue'
import { getGoods } from '@/api/home'
import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const list = ref([])
const total = ref(null)
const page = reactive({
  pageNum: 8,
  pageSize: 1
})

const getList = () => {
  let params = {
    page: page.pageSize,
    page_size: page.pageNum,
    is_top: 1
  }
  getGoods(params).then((res) => {
    if (res.code == 200) {
      list.value = res.data.list
      total.value = res.data.total
    }
  })
}
getList()
const onChange = (val) => {
  page.pageSize = val
  getList()
}
const gopage = (val, i) => {
  if (val === 'allGame') {
    router.push({ path: '/allGame', query: { type: 'buy' } })
  } else if (val === 'wares') {
    if (i.type == 0) {
      router.push({ path: '/goods', query: { id: i.id, type: 'buy' } })
    } else if (i.type == 2) {
      router.push({ path: '/goods', query: { id: i.id, type: 'partBidding' } })
    }
  }
}
onMounted(() => {})
</script>

<style scoped lang="less">
.best-box {
  // margin: 0 360px;
  margin-top: 35px;
  padding-bottom: 20px;
  .best-box-title {
    display: flex;
    justify-content: space-between;
    .left {
      width: 160px;
      display: flex;
      p {
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 8px;
        margin-top: 5px;
      }
    }
    .right {
      line-height: 42px;
      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #00b6fa;
        cursor: pointer;
      }
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .item {
      width: 288px;
      height: 292px;
      background: #ffffff;
      border-radius: 12px;
      //margin-left: 5px;
      margin-top: 16px;
      margin-right: 10px;
      .img {
        position: relative;
        img {
          width: 288px;
          height: 140px;
          border-radius: 12px 12px 0 0;
        }
        .official {
          width: 59px;
          height: 19px;
          font-size: 12px;
          text-align: center;
          font-family: YouSheBiaoTiHei;
          color: #ffffff;
          position: absolute;
          background: url(../../../assets/images/authority-bg.png) no-repeat;
          top: 8px;
          left: 8px;
        }
        .user {
          width: 59px;
          height: 19px;
          font-size: 12px;
          text-align: center;
          font-family: YouSheBiaoTiHei;
          color: #ffffff;
          position: absolute;
          background: url(../../../assets/images/individual-bg.png) no-repeat;
          top: 8px;
          left: 8px;
        }
      }
      .introduce {
        width: 100%;
        padding: 6px 12px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
      }
      .price {
        display: flex;
        justify-content: space-between;
        padding: 8px 12px;
        border-bottom: 1px solid #f2f2f2;
        P:nth-child(1) {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }
        P:nth-child(2) {
          font-size: 20px;
          font-family: DIN-Bold, DIN;
          font-weight: bold;
          color: #ff401b;
        }
      }
      .district {
        margin-top: 12px;
        margin-left: 12px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .item:nth-of-type(4n + 0) {
    margin-right: 0;
  }
}
</style>
