<template>
  <div class="box">
    <ul>
      <li @click="gopage(1)">
        <img src="@/assets/images/right-download-icon.png" alt="" />
        <p>下载APP</p>
      </li>
      <li @click="gopage(2)">
        <img src="@/assets/images/right-shop-icon.png" alt="" />
        <p>我要购买</p>
      </li>
      <li @click="gopage(3)">
        <img src="@/assets/images/right-sell-icon.png" alt="" />
        <p>我要出售</p>
      </li>
      <li @click="gopage(4)">
        <img src="@/assets/images/right-service-icon.png" alt="" />
        <p>联系客服</p>
      </li>
      <li @click="backTop">
        <img src="@/assets/images/right-operate-icon.png" alt="" />
        <p>TOP</p>
      </li>
    </ul>
  </div>
  <login ref="loginModal"></login>
</template>
<script setup>
import login from '@/components/Login/index.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { getChatService } from '@/api/service'
const router = useRouter()
const loginModal = ref(null)
const gopage = (e) => {
  if (e === 1) {
    router.push('/download')
  } else if (e === 2) {
    router.push({ path: 'allGame', query: { type: 'buy' } })
  } else if (e === 3) {
    router.push({ path: 'allGame', query: { type: 'sell' } })
  } else if (e === 4) {
    if (localStorage.getItem('token')) {
      getChatService({ type: 1 }).then((res) => {
        if (res.code == 0) {
          let to = router.resolve({ path: '/service', query: { account: res.data.id } })
          window.open(to.href, '_blank')
        }
      })
    } else {
      loginModal.value.visible = true
      loginModal.value.loginVisible = true
    }
  }
}
const emit = defineEmits(['backTop'])
const backTop = () => {
  emit('backTop')
}
</script>
<style lang="less" scoped>
.box {
  width: 72px;
  height: 355px;
  background: #ffffff;
  border-radius: 12px;
  position: fixed;
  right: 20px;
  top: 260px;
  ul {
    text-align: center;
    li {
      height: 71px;
      cursor: pointer;
      img {
        margin-top: 20px;
      }
      p {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        margin-top: 5px;
      }
    }
  }
}
</style>
